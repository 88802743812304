.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-size: cover;
  background-position: center;

}

.login-section {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 70px;
  border-radius: 8px;
}


.users-auth{
  display: flex;
  flex-direction:row;
  gap: 40px;
  margin-top: 10px;
} 

.hrs{
margin-top: 50px;
}
.user-title{
  font-weight: 700;
}

.logo {
  text-align: center;
  margin-bottom: 20px;
}

.logo img {
  width: 120px;
  height: auto;
  margin-bottom: 20px;
}

.form-container {
  margin-top: 50px;
  margin-bottom: 1px;
  margin-left: 30px;
  margin-right: 30px;
}


.labels {
  display: block;
  font-weight: bold;
  margin-top: 10px ;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}

.inputs {
  width: 100%;
  padding: 10px 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  line-height: 25px; 
}


.login-button {
  display: block;
  width: 50%;
  padding: 10px;
  background-color: #00C2A2;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto; /* Center the button horizontally */
}

.psswd-forgotten {
  margin-bottom: 20px;
  margin-top: 20px;
}

.checkbox-remember{
  margin-bottom: 20px;
  margin-top: 20px;
}

.checkbox-input {
  margin-right: 20px;
}

.sign-up-link {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.sign-up-link a {
  color: #00C2A2;
  text-decoration: none;
}

.error-message {
  color: red;
}

.all-usernames p{
  margin-left: 30px;
}

  /* Small devices (up to 576px) */
  @media (max-width: 576px) {
    body {
      font-size: 14px;
    }

    .users-auth{
      display: flex;
      flex-direction:column;
      gap: 0;
    } 
  }

  /* Medium devices (577px to 768px) */
  @media (min-width: 577px) and (max-width: 768px) {
    body {
      font-size: 16px;
    }

    .users-auth{
      display: flex;
      flex-direction:column;
      gap: 0;
    } 
  }



/* Medium Devices (Tablets and Smaller Laptops) */
@media (min-width: 769px) and (max-width: 992px) {
  body {
    font-size: 16px;
  }

  .users-auth{
    display: flex;
    flex-direction:column;
    gap: 0;
  } 

  .login-container {
    gap: 50px;
  }

  .login-container {
    height: 100vh;
    padding-left: 50px;
    padding-right: 50px;
  }

  .login-section {
    width: 100%;
    max-width: 500px;
    margin: 20% auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  .labels {
    margin: 5px 52px;
    font-size: 14px;
  }

  .inputs {
    width: 75%;
    padding: 15px;
    margin: 10px auto;
    display: block;
  }

  .image-section img {
    max-width: 90%;
    max-height: 90%;
  }

  .label-mdp-oublié {
    font-size: 14px;
  }

  .sign-up-link {
    font-size: 12px;
  }
}

/* Large Devices (Desktops and Larger Laptops) */
@media (min-width: 993px) and (max-width: 1200px) {
  body {
    font-size: 18px;
  }


}



/* Extra Large Devices (Large Desktops) */
@media (min-width: 1201px) and (max-width: 1900px) {
  body {
    font-size: 18px;
  }

  .login-container {
    gap: 0px;
  }

  .login-container {
    padding-left: 0px;
    padding-right: 0px;
  }


  .login-section {
    width: 100%;
    max-width: 500px;
    margin: 1% auto;
    padding-left: 0px;
    padding-right: 0px;
  }

  .inputs {
    width: 100%;
    padding-top: 15px;
    display: block;

  }
}

