/* 
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  

  .logo-header {
    max-width: 100px;
  }
  
  .icon-shopping-cart {
    font-size: 24px;
    cursor: pointer;
  }
  

  .fa-shopping-cart {
    color: #333;
  }

  .fa-shopping-cart:hover {
    color: #00C2A2;
  }
  

.header-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }
  
  .header-list li {
    margin-right: 20px;
    font-size: 18px; 
    cursor: pointer;
    font-weight: 600;
  }

  .header-list li:hover {
    color: #00C2A2;
  }

  .cart-count {
    background-color: red;
    color: white;
    font-weight: 700;
    border-radius: 50%;
    padding: 3px 3px;
    font-size: 12px;
    position: absolute;
    top: 25px;
    right: 13px;
    transform: translate(50%, -50%);
  } */

  .navigation {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 0rem;
    background-color: #fff;
    color: black;
    box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  }

  .brand-name {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    margin-left: 1rem;
  }
  .navigation-menu {
    margin-left: auto;
  }

  .navigation-menu ul {
    display: flex;
    padding: 0;
  }
  .navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
  }
  .navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
    font-weight: 700;
    color: black;
  }

  .hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #00c2a2;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }

  .hamburger:hover {
    background-color: #00C2A2;
  }

  .brand-name img{
      width: 80px;
      height: auto;
  }


  @media screen and (max-width: 768px) {
    .hamburger {
      display: block;
    }
  }

  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      position: absolute;
      top: 60px;
      left: 0;
      flex-direction: column;
      width: 100%;
      height: calc(100vh - 77px);
      background-color: white;
      border-top: 1px solid black;
    }
    .navigation-menu li {
      text-align: center;
      margin: 0;
    }
    .navigation-menu li a {
      color: black;
      width: 100%;
      padding: 1.5rem 0;
    }
    .navigation-menu li:hover {
      background-color: #eee;
    }
  }

  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      dipslay: none;
    }
  }

  @media screen and (max-width: 768px) {
    .navigation-menu.expanded ul {
      display: block;
    }
  }