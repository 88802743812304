/* HomePage.css */

/* Style for the main container */
.home-container {
    margin: 0 auto;
  }
  
  /* Style for the content */
  .content {
    display: flex;
    align-items: center;
  }
  
  /* Style for the left side (title) */
  .left {
    flex: 1;
  }
  
  /* Style for the title */
  .left h1 {
    font-size: 24px;
    color: #333;
    margin-left: 40px;
  }
  
  /* Style for the right side (search bar) */
  .right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  
  /* Style for the search bar */
  .search-bar {
    display: flex;
    align-items: center;
    margin-right: 40px;
  }
  
  /* Style for the search input */
  .search-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 16px;
  }
  
  /* Style for the search button */
  .btn-search {
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }

  /* Style for the search icon */
  .fa-search {
    font-size: 18px;
  }

  .btn-search:hover  {
    background-color: #00C2A2;
  }

  .homepage-cards {
    display: flex;
    justify-content: center;
  }
  

  
  @media (max-width: 576px) {
    body {
      font-size: 14px;
    }

    .content{
      margin: 10px;
      
    }
    
    .left h1{
      font-size: 14px;
      margin-left: 0;
    }

    .search-bar {
      margin-right: 0;
  }

  .search-input {
    padding: 5px;
    margin-right: 5px;
    font-size: 12px;
}

.btn-search {
  padding: 5px 10px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}


  }

  @media (min-width: 577px) and (max-width: 768px) {
    body {
      font-size: 16px;
    }

  }

  @media (min-width: 769px) and (max-width: 992px) {
    body {
      font-size: 16px;
    }

  }

  @media (min-width: 993px) and (max-width: 1200px) {
    body {
      font-size: 18px;
    }
  
  
  }

  @media (min-width: 1201px) and (max-width: 1900px) {
    body {
      font-size: 18px;
    }
  

  }
  