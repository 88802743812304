/* Signup.css */


.signup-container {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px; 
  margin-bottom: auto;
  padding: 40px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}


.form-section{
  margin-left: 50px;
  margin-right: 50px;
}

.logo-section {
    display: flex;
    align-items: center;
    justify-content: center;
  }

.logo-section img {
    width: 150px; 
    height: auto;
    margin-bottom: 20px;
  }

  .signup-title{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
  }


.labels-signup {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
}

.inputs-signup {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding-bottom: 10px;
    padding-top: 5px;
}

.signup-button {
    display: block;
    width: 50%;
    padding: 10px;
    background-color: #00C2A2;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
}

.label-regle-mdp{
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
}

.regle-checked{
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 16px;
  margin-left: 10px;
  margin-right: 10px;
}

.wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.box{
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
}

.input-with-icon-div{
  display: flex;
}

.custom-input{
  width: 100%;
  border: none;
  outline: none;
}

.icon-span {
  cursor: pointer;
  margin-left: 10px;
}

.tracker-box{
  background-color: #5a9ed6;
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.09em;
  padding: 15px;
  border-radius: 5px;
  margin-top: 20px;
}

.tracker-box div{
  margin: 5px 0;
}

.tracker-box .validated{
  color: rgba(255, 255, 255, 0.5);
}

.list-icon{
  margin-right: 8px;
}

.list-icon.green{
  color: rgb(255, 255, 255);
}


  /* Small devices (up to 576px) */
  @media (max-width: 576px) {
    body {
      font-size: 14px;
    }


  }