.consulter-button {
  background-color: #00c2a2 !important;
  color: #fff !important;
}

.consulter-button:hover {
  background-color: #214c56;
}

.product-name p {
  font-weight: 600;
  margin-bottom: 50px;
}

.bottom-content p {
  font-weight: 700;
  font-size: 25px;
} 

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 30px; 
  margin-top: 40px;
}

 /* Small devices (up to 576px) */
 @media (max-width: 576px) {

  .homepage-cards{
    margin-top: 15em;
  }

  .grid-container {
    grid-template-columns: 1fr!important;
    gap: 30px; 
    margin-top: 20px;
  }

}


@media (min-width: 577px) and (max-width: 768px) {


  .product-name p {
    font-size: 18px; 

  }

  .product-desc p{
    font-size: 14px;
  }

  .bottom-content p {
    font-size: 16px;
  }

  .grid-container {
    grid-template-columns: 1fr 1fr!important;
    gap: 30px; 
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
  }

}


@media (min-width: 769px) and (max-width: 992px) {

  .product-name p {
    font-size: 18px; 

  }

  .product-desc p{
    font-size: 14px;
  }

  .bottom-content p {
    font-size: 16px;
  }

  .grid-container {
    grid-template-columns: 1fr 1fr!important;
    gap: 30px; 
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
  }


}

@media (min-width: 993px) and (max-width: 1200px) {

  .grid-container {
    grid-template-columns: 1fr 1fr 1fr!important;
    gap: 30px; 
    margin-top: 20px;
    margin-left: 50px;
    margin-right: 50px;
  }




}

@media (min-width: 1201px) and (max-width: 1900px) {
  
  .grid-container {
    margin-left: 50px;
    margin-right: 50px;
  }



}
